import React from "react";
import { Link } from "react-router-dom";

export const Footer = () => {
  return (
    <>
      <footer id="footer" className="footer ">
        <div className="footer-top">
          <div className="container">
            <div className="row">
              <div className="col-lg-3 col-md-6 col-12">
                <div className="single-footer">
                  <h2>About Us</h2>
                  <p>Lorem ipsum dolor sit am consectetur adipisicing elit do eiusmod tempor incididunt ut labore dolore magna.</p>
                  <ul className="social">
                    <li>
                    <Link to={"#"}>
                        <i className="icofont icofont-facebook"></i>
                    </Link>
                    </li>
                    <li>
                    <Link to={"#"}>
                        <i className="icofont icofont-google-plus"></i>
                      </Link>
                    </li>
                    <li>
                    <Link to={"https://www.instagram.com/sms_pharmacy_"} target="_blank">
                        <i className="icofont icofont-instagram"></i>
                      </Link>
                    </li>
                  </ul>
                </div>
              </div>
              <div className="col-lg-3 col-md-6 col-12">
                <div className="single-footer f-link">
                  <h2>Quick Links</h2>
                  <div className="row">
                    <div className="col-lg-6 col-md-6 col-12">
                      <ul>
                        <li>
                          <Link to={"/"}>
                            <i className="fa fa-caret-right" aria-hidden="true"></i>Home
                          </Link>
                        </li>
                        <li>
                          <Link to={"/about-us"}>
                            <i className="fa fa-caret-right" aria-hidden="true"></i>About Us
                          </Link>
                        </li>
                        <li>
                          <Link to={"/contact-us"}>
                            <i className="fa fa-caret-right" aria-hidden="true"></i>Contact Us
                          </Link>
                        </li>
                      </ul>
                    </div>
                  </div>
                </div>
              </div>

              <div className="col-lg-3 col-md-6 col-12">
                <div className="single-footer f-link">
                  <h2>Products</h2>
                  <div className="row">
                    <div className="col-lg-12 col-md-12 col-12">
                      <ul>
                        <li>
                          <Link to={"/cardiovascular"}>
                            <i className="fa fa-caret-right" aria-hidden="true"></i>Cardiovascular
                          </Link>
                        </li>
                        <li>
                          <Link to={"/anti-diabetic"}>
                            <i className="fa fa-caret-right" aria-hidden="true"></i>Anti Diabetic
                          </Link>
                        </li>
                        <li>
                          <Link to={"/lipid-lowering"}>
                            <i className="fa fa-caret-right" aria-hidden="true"></i>Lipid Lowering
                          </Link>
                        </li>
                        <li>
                          <Link to={"/pain-management"}>
                            <i className="fa fa-caret-right" aria-hidden="true"></i>Pain Management
                          </Link>
                        </li>
                      </ul>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-lg-3 col-md-6 col-12">
                <div className="single-footer">
                  <h2>Open Hours</h2>
                  <ul className="time-sidual">
                    <li className="day">
                      Monday - Saturday <span>9 AM - 9 PM</span>
                    </li>
                    <li className="day">
                      Sunday <span>10 AM - 2 PM</span>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="copyright">
          <div className="container">
            <div className="row">
              <div className="col-lg-12 col-md-12 col-12">
                <div className="copyright-content">
                  <p>
                    © Copyright {new Date().getFullYear()} | All Rights Reserved. Design and Developed by
                    <Link to={"https://marstechnolabs.com"} target="_blank">
                      MARS TechnoLabs
                    </Link>{" "}
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </footer>
    </>
  );
};

export default Footer;